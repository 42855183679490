
import { useStorage } from '@/composables/useStorage';
import { CheckSheet, CheckSheetEntry, CheckSheetTemplate, Medication, Rafter, Location, WeeklyTask, Stable, CheckSheetEntryTemplate, PlannedStable } from "@/types";
import StablesModal from '@/views/CoreData/StablesModal.vue';
import { ObjectID } from 'bson';
import dayjs from 'dayjs';
import { inject } from 'vue';
import { GlobalCheckSheetKey, GlobalCheckSheetTemplateKey, GlobalRafterKey } from './globals';
import { restClient } from './restClient';
import { syncData } from './syncData';

export function checksheetFunctions() {
    const createChecksheet = async (rafter: Rafter) => {
        const { getLocalStorage } = await useStorage();

        const checkSheetTemplates = await getLocalStorage('CheckSheetTemplate');
        const checkSheetTemplate = checkSheetTemplates.filter( (x: CheckSheetTemplate) => x.location == rafter.location)[0];

        const checkSheet = {
            id: new ObjectID().toString(),
            entries: [], 
            medications: [],
            weeklyTasks: [],
            location: rafter.location,
            rafter: rafter.id
        } as CheckSheet;

        checkSheet.medications = getMedication(checkSheetTemplate, rafter.stablingDate);
        checkSheet.weeklyTasks = getWeeklyTasks(checkSheetTemplate, rafter.stablingDate);

        return checkSheet;
    };

    const getMedication = ( checkSheetTemplate: CheckSheetTemplate, fromDate: Date ) => {
        const medications: Medication[] = [];

        checkSheetTemplate?.medications?.forEach((medication) => {
            if( medication.medicationDateFromDay !== undefined ){
                medication.medicationDateNominal = dayjs(fromDate).add((medication.medicationDateFromDay as number)-1, 'days').toDate();
            }
            medications.push( medication );
        });

        return medications;
    };

    const getWeeklyTasks = ( checkSheetTemplate: CheckSheetTemplate, stablingDate: Date ) => {
        const weeklyTasks: WeeklyTask[] = [];

        checkSheetTemplate?.weeklyTasks?.forEach((task) => {
            task.id = new ObjectID().toString();
            task.created = stablingDate;
            weeklyTasks.push( task );
        });

        return weeklyTasks;
    };

    const getHistoricalTotalCount = (currentRafter: Rafter, checkSheet: CheckSheet, date: Date, stableId: (string | undefined)[] = []) => {
        const ret: { name: string|undefined; count: number; gender: string; id: string|undefined}[] = [];

        const allAreas = [] as PlannedStable[];
        
        if( stableId.length == 0){
            const lastStables = currentRafter.stables?.filter( s => 
                        dayjs(s.stablingDate).hour(7).isBefore(dayjs(date).hour(8)) 
                    || (dayjs(date).hour(8).isBefore(dayjs(currentRafter.stablingDate).hour(7)) && dayjs(s.stablingDate).subtract(1, 'day').hour(7).isBefore(dayjs(date).hour(8)))
                )
            const lastStable = lastStables && lastStables.length > 0 ? lastStables.reduce((prev, current) => (prev && prev.stablingDate > current.stablingDate) ? prev : current) : undefined
            currentRafter?.stables?.filter(s => s.location == lastStable?.location).forEach( ps => {
                if( ps.areas){
                    if(ps.areas.top){
                        allAreas.push(ps.areas.top);
                    }
                    if(ps.areas.bottom){
                        allAreas.push(ps.areas.bottom)
                    }
                }
            });
        }
        else{
            currentRafter?.stables?.filter(s => stableId.includes(s.id)).forEach( ps => {
                if( ps.areas){
                    if(ps.areas.top){
                        allAreas.push(ps.areas.top);
                    }
                    if(ps.areas.bottom){
                        allAreas.push(ps.areas.bottom)
                    }
                }
            });
        }
        
        allAreas.forEach( (area: PlannedStable) => {
            const values = checkSheet?.entries?.filter( obj => obj.stable == area.id && obj.key?.startsWith('Verlust') && dayjs(obj.created).hour(7).isBefore(dayjs(date).hour(8), 'days'));
            let currentQuantity = area.quantity;
            values?.forEach( (entry: CheckSheetEntry) => {
                if( entry.value && entry.value.length > 0 && currentQuantity){
                    currentQuantity -= +(entry.value as string);
                }
            });
            currentRafter.restablings?.filter( r => r.isOutstabling && dayjs(r.restablingDate).hour(8).isBefore(dayjs(date).hour(7)) && r.originAreaId == area.id).forEach(r => {
                if( currentQuantity && r.quantity)
                currentQuantity -= r.quantity;
            });
            ret.push({
                name: area.name,
                count: currentQuantity,
                gender: area.gender ? 'Hennen' : 'Hahnen',
                id: area.id
            });
        });
        return ret;
    }

    const findRafter = async (currentLocation: Location) => {
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const { queueUpload } = await syncData();
        let currentRafter: Rafter|undefined = undefined;
        const rafters = await getLocalStorage('Rafter');
        const stables = await getLocalStorage('Stable');
        try{    
            // const customRafter = await getLocalStorage('currentCustomRafter');
            // if(customRafter){
            //     currentRafter = rafters.find( (elem: Rafter) => elem.id == customRafter);
            //     await setLocalStorage('currentRafter', currentRafter);
            // }
            // else{

            rafters.filter( (rafter: Rafter) => !rafter.isFinished && dayjs().diff(dayjs(rafter.stablingDate), 'weeks') < 30 && dayjs(rafter.stablingDate).subtract(1, 'day').hour(7).isBefore(dayjs().hour(8))).forEach( async (rafter: Rafter) => {
                if( rafter.stables ){
                    const lastStable = rafter.stables.filter( s => dayjs(s.stablingDate).hour(7).isBefore(dayjs().hour(8))).reduce((prev, current) => (prev && prev.stablingDate > current.stablingDate) ? prev : current);
                    if( lastStable?.stable ){
                        const stable = stables.find( (s: PlannedStable) => s.id == lastStable.stable )
                        if( stable ){
                            if( stable.location == currentLocation.id ){
                                currentRafter = rafter;
                            }
                            if( rafter.currentLocation != stable.location ){
                                rafter.currentLocation = stable.location;
                                await queueUpload('u', 'Rafter', rafter.id, 'CurrentLocation', rafter.currentLocation);
                            }
                            await setLocalStorage('Rafter', rafters);
                        }
                        
                    }
                }
                
            });

            // currentRafter = rafters.find( (elem: Rafter) => elem.location == currentLocation.id && dayjs(elem.stablingDate).subtract(2, 'days').format('YYYYMMDD') <= dayjs().format('YYYYMMDD') && dayjs(elem.restablingDate).add(1, 'day').format('YYYYMMDD') >= dayjs().format('YYYYMMDD'));
            // if(!currentRafter){
            //     console.log('!currentRafter')
            //     const { findCurrentRafter } = restClient();
            //     currentRafter = await findCurrentRafter(currentLocation.id as string);
            // }
            await setLocalStorage('currentRafter', currentRafter);
            // }
            
        }
        catch(e){
            const { findCurrentRafter } = restClient();
            currentRafter = await findCurrentRafter(currentLocation?.id as string);
            await setLocalStorage('currentRafter', currentRafter);
        }
        if( !currentRafter ){
            await setLocalStorage('currentCheckSheet', undefined);
        }

        return currentRafter;
    }
    const findExistingCheckSheet = async (currentRafter: Rafter) =>{
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const checkSheets = await getLocalStorage('CheckSheet');
        let checkSheet: CheckSheet;
        try{   
            const customRafter = await getLocalStorage('currentCustomRafter');
            if(customRafter){
                checkSheet = checkSheets.find( (elem: CheckSheet) => elem.rafter == customRafter.id);
            } 
            else{
                checkSheet = checkSheets.find( (elem: CheckSheet) => elem.rafter == currentRafter?.id);
                if(!checkSheet){
                    const { findCurrentChecksheet } = restClient();
                    checkSheet = await findCurrentChecksheet(currentRafter?.id as string);
                }
            }
            await setLocalStorage('currentCheckSheet', JSON.parse(JSON.stringify(checkSheet)));

        }
        catch(e){
            const { findCurrentChecksheet } = restClient();
            checkSheet = await findCurrentChecksheet(currentRafter?.id as string);
            await setLocalStorage('currentCheckSheet', JSON.parse(JSON.stringify(checkSheet)));
        }

        return checkSheet;
    };
    
    const findHistoricCheckSheet = async (rafter: Rafter) =>{
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const checkSheets = await getLocalStorage('CheckSheet');
        try{   
            const historicCheckSheet = checkSheets.find( (elem: CheckSheet) => elem.rafter == rafter?.id);
            return historicCheckSheet;
        }
        catch(e){
            console.log(e);
        }

        return null;
    };
    const findChecksheetTemplate = async (locationId: string) => {
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const templates = await getLocalStorage('CheckSheetTemplate');
        let template = undefined;
        try{    
            template = templates.find( (elem: CheckSheetTemplate) => elem.location == locationId);
            await setLocalStorage('currentCheckSheetTemplate', template);
        }
        catch(e){
            const { findChecksheetTemplateFromLocation } = restClient();
            template = await findChecksheetTemplateFromLocation(locationId);
            await setLocalStorage('currentCheckSheetTemplate', template);
        }
        return template;
    };
    const checkNewChecksheetEntries = async (checksheetId: string, date: Date, stableIds: string[]) => {
        const { checkNewChecksheetEntries } = restClient();
        return await checkNewChecksheetEntries(checksheetId, date, stableIds);
    };
    const getExistingChecksheetEntries = async (checksheetId: string, date: Date) => {

        const { getExistingChecksheetEntries } = restClient();
        return await getExistingChecksheetEntries(checksheetId, date);
    };
    const getOrderedKeys = (checkSheet: CheckSheet, stableId: string) => {
        if(checkSheet.entries !== undefined && checkSheet.entries.length > 0){
            const day = dayjs(checkSheet.entries[0].created).format('YYYYMMDD');

            return checkSheet.entries.filter( (item) => item.created !== undefined && dayjs(item.created).format('YYYYMMDD') == day && item.stable == stableId).sort( item => item.sortOrder).map( (item) => ({
                "key": item.key
            }));
        }
        return null;
    };

    const getChecksheetTemplateSettings = async (checkSheet: CheckSheet, rafter: Rafter) => {
        
        if( rafter.stables ){
            const locations = [...new Set(rafter.stables.map( s => s.location))]

            locations.forEach( async (locationId) => {
                if( locationId ){
                    const areas = [] as string[];
                    rafter.stables?.filter( s => s.location == locationId).forEach( s => {
                        if( s.areas?.top?.id)
                            areas.push(s.areas.top.id);
                        if( s.areas?.bottom?.id)
                            areas.push(s.areas.bottom.id);
                    })
                    const template = await getChecksheetTemplate(locationId);
                    template?.entries?.forEach((entry: CheckSheetEntryTemplate) => {
                        const values = checkSheet.entries?.filter( (obj: CheckSheetEntry) => obj.key == entry.key && obj.stable && areas.includes(obj.stable) );
                        values?.forEach( (value: CheckSheetEntry) => {
                            value.unit = entry.unit;
                            value.unifiedValue = entry.unifiedValue;
                            value.sortOrder = entry.sortOrder;
                        });
                    });
                }
            });
            
        }
        
    }

    const getChecksheetTemplate = async (locationId: string) => {
        const { getLocalStorage } = await useStorage();
        const templates = (await getLocalStorage('CheckSheetTemplate') ?? []) as CheckSheetTemplate[];
        return templates.find( (t: CheckSheetTemplate) => t.location == locationId);
    }

    const sortChecksheetMedications = (checkSheet: CheckSheet | undefined) => {
        checkSheet?.medications?.sort( (a:Medication, b:Medication) => {
            return dayjs(a.medicationDateNominal).isSame(dayjs(b.medicationDateNominal), 'day') ? (a.sortOrder < b.sortOrder ? -1 : 1) : (dayjs(a.medicationDateNominal).isBefore(dayjs(b.medicationDateNominal)) ? -1 : 1)
        });
    }

    const getCurrentAreas = (rafter: Rafter, date: Date, locationId: string|undefined = undefined) => {
        const allAreas = [] as PlannedStable[]

        if( rafter.stables){
            rafter.stables.filter( (s: PlannedStable) => dayjs(s.stablingDate).hour(7).isBefore(dayjs().hour(8).add(1, 'day')) && (!locationId || s.location == locationId)).forEach( (ps: PlannedStable) => {
                if( ps.areas){
                    if(ps.areas.top){
                        if( dayjs(ps.areas.top.stablingDate).hour(7).isBefore(dayjs().hour(8).add(1, 'day')) )
                            allAreas.push(ps.areas.top)
                    }
                        
                    if(ps.areas.bottom){
                        if( dayjs(ps.areas.bottom.stablingDate).hour(7).isBefore(dayjs().hour(8).add(1, 'day')) )
                            allAreas.push(ps.areas.bottom)
                    }
                }
            });
        }

        return allAreas;
    }

    const getAreaHistory = (rafter: Rafter, areaId: string) => {
        const ids = [areaId]

        const restabling = rafter.restablings?.find( r => r.newAreaId == areaId)
        if( restabling && restabling.originAreaId && restabling.originStableObj){
            ids.splice(0, 0, restabling.originAreaId)
        }
        return ids
    }

    return{
      createChecksheet,
      getMedication,
      getHistoricalTotalCount,
      findRafter,
      findExistingCheckSheet,
      findHistoricCheckSheet,
      findChecksheetTemplate,
      checkNewChecksheetEntries,
      getExistingChecksheetEntries,
      getOrderedKeys,
      getChecksheetTemplate,
      getChecksheetTemplateSettings,
      sortChecksheetMedications,
      getCurrentAreas,
      getAreaHistory
    }
}