<template>
    <ion-header>
        <ion-toolbar>
            <ion-title><small v-if="dayjs(days[3].date).isToday()">Heute</small>ToDo<small>{{ dayjs(days[3].date).format('MMMM YYYY') }} | KW {{ dayjs(days[3].date).week() }}</small></ion-title>
            <ion-button slot="end" @click="dismissModal()" shape="round">
                <ion-icon src="assets/icon/i-close.svg" slot="icon-only"/>
            </ion-button>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <div class="calendar">
            <div class="prev" @click="prevDay">
                <ion-icon src="assets/icon/i-left.svg" />
            </div>
            <div v-for="(day, i) in days" :key="day.date" :class="'day' + (i == 3 ? ' today' : '')" @click="changeDay(day)">
                <span class="dayLetter">{{ day.dd }}</span>
                <span class="dayNumber">{{ day.D }}</span>
            </div>
            <div class="next" @click="nextDay">
                <ion-icon src="assets/icon/i-left.svg" />
            </div>
        </div>
        <div class="todos">
            <div class="tasks">
                <ion-item v-for="weeklyTask in getOpenWeeklyTasks()" :key="weeklyTask" class="task" lines="none" @click="setWeeklyTaskDone(weeklyTask)">
                    <ion-label>{{ weeklyTask.name }}</ion-label>
                    <ion-button slot="end"><ion-icon v-if="weeklyTask.person"  src="assets/icon/i-check.svg" /></ion-button>
                </ion-item>
            </div>
            <div class="meds">
                <ion-item v-for="medication in medications" :key="medication" class="task" lines="none" router-link="/medikation" @click="dismissModal">
                    <ion-label>Medikation:<br />{{ medication.name }}</ion-label>
                    <ion-button slot="end"><ion-icon src="assets/icon/i-arrow.svg" /></ion-button>
                </ion-item>
            </div>
            <div class="tasks done">
                <ion-item v-for="doneWeeklyTask in getDoneWeeklyTasks()" :key="doneWeeklyTask" class="task" lines="none">
                    <ion-label>{{ doneWeeklyTask.name }}</ion-label>
                    <ion-button slot="end"><ion-icon src="assets/icon/i-check.svg" /></ion-button>
                </ion-item>
            </div>
        </div>
    </ion-content>
</template>

<script lang="ts">
import { IonButton, IonContent, IonIcon, IonLabel, IonItem, IonHeader, IonTitle, IonToolbar, modalController } from '@ionic/vue';
import { defineComponent, inject, onBeforeMount, ref } from 'vue';
import { Medication, WeeklyTask } from "@/types";
import { syncData } from '@/composables/syncData';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import { useRouter } from 'vue-router';
import { ObjectID } from 'bson';
import { GlobalRafterKey, GlobalCheckSheetKey, GlobalUserKey } from '@/composables/globals';
dayjs.extend(weekOfYear);
dayjs.extend(isToday);

export default defineComponent({
    name: 'ToDoModal',
    components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonIcon, IonItem, IonLabel },
    props: {
    },
    data() {
        return {
        }
    },
    setup() {
        const medications = ref<Medication[]>([]);
        const weeklyTasks = ref<WeeklyTask[]>([]);
        const router = useRouter();
        const days = ref<any[]>([]);
        const currentDay = ref<Date>(new Date());

        const globalCheckSheet = inject(GlobalCheckSheetKey);
        const globalUser = inject(GlobalUserKey);

        onBeforeMount(async () => {
            if(globalCheckSheet?.value){
                globalCheckSheet.value.medications?.forEach( (medication: Medication) => {
                    if( dayjs(medication.medicationDateNominal).isBefore(dayjs().add(1, 'day')) ){
                        if (!medication.medicationDoses || medication.medicationDoses.length == 0) {
                            medications.value.push(medication);
                        }
                    } 
                });
            }
        });

        for(let i = -3; i < 4; i++){
            const day = dayjs().add(i, 'days');
            days.value.push({ 
                date: day,
                dd: day.format('dd'),
                D: day.format('D'),
            });
        }

        const getOpenWeeklyTasks = () => {
            return globalCheckSheet?.value?.weeklyTasks?.filter( (weeklyTask: WeeklyTask) => 
                dayjs(weeklyTask.created).isBefore(currentDay.value) && !weeklyTask.person
            );
        }
        const getDoneWeeklyTasks = () => {
            return globalCheckSheet?.value?.weeklyTasks?.filter( (weeklyTask: WeeklyTask) => 
                weeklyTask.date && dayjs(weeklyTask.date).isSame(currentDay.value, 'day') && weeklyTask.person
            );
        }

        
        return {
            days,
            dayjs,
            medications,weeklyTasks,globalUser,globalCheckSheet,getOpenWeeklyTasks,getDoneWeeklyTasks,currentDay,
            router
        };
    },
    methods: {
        changeDay(changeToDay: any){
            this.days = [];
            for(let i = -3; i < 4; i++){
                const day = changeToDay.date.add(i, 'days');
                this.days.push({ 
                    date: day,
                    dd: day.format('dd'),
                    D: day.format('D'),
                });
            }
            this.currentDay = this.days[3].date;
        },
        prevDay(){
            this.days.pop();
            const day = this.days[0].date.add(-1, 'day');
            this.days.unshift({ 
                date: day,
                dd: day.format('dd'),
                D: day.format('D'),
            });
            this.currentDay = this.days[3].date;
        },
        nextDay(){
            this.days.shift();
            const day = this.days[this.days.length-1].date.add(1, 'day');
            this.days.push({ 
                date: day,
                dd: day.format('dd'),
                D: day.format('D'),
            });
            this.currentDay = this.days[3].date;
        },
        dismissModal() {
            modalController.dismiss();
        },
        async setWeeklyTaskDone( weeklyTask: WeeklyTask ){
            weeklyTask.date = new Date();
            weeklyTask.person = this.globalUser?.id;

            const { queueUpload } = await syncData();
            await queueUpload('u', 'CheckSheet', this.globalCheckSheet?.id, 'WeeklyTasks', weeklyTask, weeklyTask.id);

            const newWeeklyTask = {
                id: new ObjectID().toString(),
                name: weeklyTask.name,
                sortOrder: weeklyTask.sortOrder,
                created: dayjs(weeklyTask.created).add(1, 'week').toDate()
            } as WeeklyTask;
            await queueUpload('i', 'CheckSheet', this.globalCheckSheet?.id, 'WeeklyTasks', newWeeklyTask);
        }
    }
});
</script>