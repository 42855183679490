
import { toastController } from '@ionic/vue'
import { restClient } from './restClient';
import { useStorage } from './useStorage'

export function updateApp() {

    const checkAppUpdate = async (registration: ServiceWorkerRegistration ) => {
        const { getVersion, getOption } = restClient();
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const onlineVersion = await getVersion();
        const requireDbUpdate = await getOption("require-db-update");
        const niceVersion = await getOption("niceVersion");
        let localVersion = await getLocalStorage('version');
        const localDbUpdate = await getLocalStorage('localDbUpdate');
        
        if( !localVersion ){
            localVersion = onlineVersion;
            await setLocalStorage('version', localVersion);
            await setLocalStorage('niceVersion', niceVersion);
            await setLocalStorage('localDbUpdate', requireDbUpdate);
        }
        else if( await getLocalStorage('dbInitialized') && (!onlineVersion || +onlineVersion > +localVersion) ){
            const { showUpdateNotification } = updateApp();
            await showUpdateNotification(registration, onlineVersion, niceVersion);
        }
        else if(localVersion == requireDbUpdate && (!localDbUpdate || +localVersion > +localDbUpdate)){
            await setLocalStorage('localDbUpdate', localVersion);
            await setLocalStorage('dbInitialized', false);
            window.location.pathname = '/login';
        }
    }

    const showUpdateNotification = async (registration: ServiceWorkerRegistration, onlineVersion: string, onlineNiceVerision: string) => {

        const toast = await toastController.create({
            message: "App Aktualisierung verfügbar!",
            duration: 20000,
            buttons: [
                {
                    text: 'Jetzt aktualisieren',
                    handler: () => {
                        console.log(registration);
                        registration?.unregister();
                        useStorage().then( (value) => {
                            value.setLocalStorage('version', onlineVersion);
                            value.setLocalStorage('niceVersion', onlineNiceVerision);
                            window.location.reload();
                        })
                    }
                }
            ],
            position: 'top'
        });
        toast.present()
    }

    return { 
        showUpdateNotification,
        checkAppUpdate
    }
}