<template>
  <ion-page>
    <ion-tabs>
        
        <ion-router-outlet></ion-router-outlet>

        <ion-tab-bar slot="bottom" :class="'mainRoutes ' + (toggleMenu ? 'open' : 'closed')">
            <div class="menuToggle">
                <div class="menuToggleButton" @click="toggleMenu = !toggleMenu" aria-label="Admin">
                    <ion-icon :src="'/assets/icon/i-menu.svg'" />
                    <ion-label v-if="hasRole('60d58e3f7975c685d0e35ca7')">Admin</ion-label>
                </div>
            </div>
            <ion-tab-button 
                v-for="route in routes.filter((x: any) => hasRole(x.meta.role))" 
                :key="route.path" 
                :tab="route.path" 
                :href="route.path" 
                :class="route.meta.customClasses" 
                @click="checkLinkType(route.path)" 
                :disabled="(route.meta.disabledOnNoRafter == true) && !currentRafter"
                :data-after="route.path == '/todo' ? getTodoCount() : ''"
            >
                <ion-icon :src="'/assets/icon/' + route.meta.icon + '.svg'" />
                <ion-label v-if="!route.meta.iconOnly">{{ route.name }}</ion-label>
            </ion-tab-button>
        </ion-tab-bar>
    </ion-tabs>
    <ion-popover trigger="tab-button-/reload" @ionPopoverWillPresent="loadInfoValues()" class="app-info-popover">
        <div class="info-container">
            <ion-grid>
                <ion-row>
                    <ion-col>
                        App-Version
                    </ion-col>
                    <ion-col>
                        <ion-label v-if="infoValues?.localVersion == infoValues?.onlineVersion" color="success">{{ infoValues?.niceAppVersion }}<br><small>Build: {{ infoValues?.localVersion }}</small></ion-label>
                        <ion-label v-else color="warning">1.{{ infoValues?.localVersion }}<br /><small>Neue Version verfügbar</small></ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        Internet
                    </ion-col>
                    <ion-col>
                        <ion-label v-if="infoValues?.isOnline" color="success">Online</ion-label>
                        <ion-label v-else color="danger">Offline</ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        WebService
                    </ion-col>
                    <ion-col>
                        <ion-label v-if="infoValues?.wsOnline" color="success">Online</ion-label>
                        <ion-label v-else color="danger">Offline</ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-button mode="md" size="small" @click="handleRefresh($event)">
                                <ion-icon slot="icon-only" src="/assets/icon/i-reload.svg"></ion-icon>
                        </ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button size="small" @click="refreshData()">Daten aktualisieren</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </ion-popover>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon, modalController, IonGrid, IonRow, IonCol, IonButton, IonPopover } from '@ionic/vue';
import { useStorage } from "@/composables/useStorage";
import { useRouter } from 'vue-router';
import { Medication, WeeklyTask } from "@/types";
import { defineComponent, inject, ref } from 'vue';
import ToDoModal from '../components/ToDoModal.vue';
import SwitchLocationModal from '../components/SwitchLocationModal.vue';
import { checksheetFunctions } from '@/composables/checksheetFunctions';
import dayjs from 'dayjs';
import { GlobalCheckSheetKey, GlobalRafterKey, GlobalUserKey } from '@/composables/globals';
import { restClient } from '@/composables/restClient';

    export default defineComponent({
        components: { IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon, IonGrid, IonRow, IonCol, IonButton, IonPopover },
        setup() {
            const toggleMenu = ref(false);

            const GlobalRafter = inject(GlobalRafterKey, undefined);
            const globalCheckSheet = inject(GlobalCheckSheetKey, undefined);
            const globalUser = inject(GlobalUserKey, undefined);

            const currentRafter = GlobalRafter?.getGlobalRafter();

            const router = useRouter();
            const routes = router.getRoutes().filter(x => x.name);

            const infoValues = ref<any>();

            router.beforeEach(async (to, from) => {
                if( to.meta.toggleMenu ){
                    toggleMenu.value = !toggleMenu.value;
                }
            })

            const getTodoCount = () => {
                if(globalCheckSheet?.value){
                    let counter = 0;
                    if( globalCheckSheet.value.weeklyTasks ){
                        counter += globalCheckSheet.value.weeklyTasks.filter( (weeklyTask: WeeklyTask) => 
                                        dayjs(weeklyTask.created).isBefore(dayjs().add(1, 'day')) && !weeklyTask.person
                                    ).length;

                    }
                    if( globalCheckSheet.value.medications ){
                        counter += globalCheckSheet.value.medications.filter( (medication: Medication) => {
                                    if( dayjs(medication.medicationDateNominal).isBefore(dayjs().add(1, 'day')) ){
                                        if (!medication.medicationDoses || medication.medicationDoses.length == 0) {
                                            return true;
                                        }
                                    } 
                                    return false;
                                }).length;
                    }
                    return counter > 0 ? counter : '';
                }
                return "";
            };

            return {
                routes,
                globalUser,
                currentRafter,getTodoCount,
                toggleMenu,
                infoValues,
                router,
                appVersion: inject<string>('appVersion'),

            }
        },
        methods: {
            hasRole(role: string | undefined){
                if( !role ) return false;
                if(role.length == 0) return true;
                const find = this.globalUser?.roles?.find( r => {
                    return role.indexOf(r as string) > -1;
                });
                return find !== undefined;
            },
            async checkLinkType(path: string){
                if(path == '/todo'){
                    const position = document.getElementById('tab-button-/todo')?.getBoundingClientRect();
                    const modal = await modalController
                        .create({
                            component: ToDoModal,
                            cssClass: 'pd_todo_modal',
                            showBackdrop: false,
                            componentProps: {
                            },
                        });
                    if(position){
                        modal.style.paddingLeft = position?.x.toFixed() + 'px';
                        modal.style.paddingBottom = ((window.innerHeight - position.y + 13).toFixed()) + 'px';
                    }

                    modal.onDidDismiss().then((data : any) => {
                        if(data?.data){
                            console.log(data);
                        }
                    });
                    return modal.present();
                }
                else if(path == '/switch'){
                    const position = document.getElementById('tab-button-/switch')?.getBoundingClientRect();
                    const modal = await modalController
                        .create({
                            component: SwitchLocationModal,
                            cssClass: 'pd_switchlocation_modal',
                            showBackdrop: false,
                            componentProps: {
                            },
                        });
                    if(position){
                        modal.style.paddingBottom = ((window.innerHeight - position.y + 13).toFixed()) + 'px';
                    }

                    modal.onDidDismiss().then(async (data : any) => {
                        if(data?.data){
                            const { setLocalStorage } = await useStorage();
                            const { findRafter, findExistingCheckSheet, findChecksheetTemplate } = checksheetFunctions();
                            await setLocalStorage('currentLocation', JSON.parse(JSON.stringify(data.data)));

                            const currentRafter = await findRafter(data.data);
                            if( currentRafter ){
                                await findExistingCheckSheet(currentRafter);
                            }
                            await findChecksheetTemplate(data.data.id);

                            window.location.reload();
                        }
                    });
                    return modal.present();
                }
                else if (path == '/reload'){
                    // window.location.reload();
                }
            },
            async loadInfoValues(){
                const { getVersion, getOption } = restClient()
                const { getLocalStorage } = await useStorage()
                const onlineVersion = await getVersion()
                const requireDbUpdate = await getOption("require-db-update")
                const niceVersion = await getOption("niceVersion")
                let localVersion = await getLocalStorage('version')
                const localDbUpdate = await getLocalStorage('localDbUpdate')
                this.infoValues = {
                    niceAppVersion: niceVersion,
                    isOnline: navigator.onLine,
                    wsOnline:  onlineVersion != undefined,
                    localVersion: localVersion,
                    onlineVersion: onlineVersion,
                    localDBVersion: localDbUpdate,
                    onlineDBVersion: requireDbUpdate
                }
            },
            async refreshData(){
                const { setLocalStorage } = await useStorage();
                await setLocalStorage('dbInitialized', false);
                this.router.replace('/login');
                window.location.reload();
            },
            handleRefresh(event: any){
                window.location.reload();
            }
        }
    });
</script>