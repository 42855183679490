import { SyncItem } from '@/types';
import dayjs from 'dayjs';
import { HTTP } from './http-common';
import { useStorage } from '@/composables/useStorage';

export function restClient() {

    const loginUser = async (username: string | undefined, password: string | undefined) => {
        const data = {
            Username: username,
            Password: password
        };
        try {
            const response = await HTTP.post('/Login', data);
            return response;
        } catch (e) {
            console.log(e);
        }
        return undefined;
    };

    const loadData = async (type: string, start: number, amount: number, sortColumn = "") => {
        try {
            let request = '/' + type + '?start=' + start + '&amount=' + amount;

            if( sortColumn.length > 0 ){
                request += '&sortColumn=' + sortColumn;
            }
            const response = await HTTP.get(request);

            return await response.data;
        } catch (e) {
            console.log(e);
        }
    };

    const loadChildren = async (type: string, children: string) => {
        try {
            const response = await HTTP.get('/' + type + '?children=' + children);
            return await response.data;
        } catch (e) {
            console.log(e);
        }
    };

    const countData = async (type: string) => {
        try {
            const response = await HTTP.get('/' + type + '/count');
            return await response.data;
        } catch (e) {
            console.log(e);
        }
    };

    const getItem = async (type: string, _id: string) => {
        try {
            const response = await HTTP.get('/' + type + '/' + _id);
            return response.data;
        } catch (e) {
            console.log(e);
        }
        return undefined;
    };

    const deleteItem = async (item: SyncItem, failedUpdates: SyncItem[]) => {
        try {
            const response = await HTTP.post('/' + item.collection + '/delete', item);
            return response.status;
        } catch (e) {
            console.log(e);
            failedUpdates.push(item);
        }
        return 500;
    };

    const createItem = async (type: string, data: any, failedUpdates: SyncItem[]) => {
        try {
            const response = await HTTP.post('/' + type + '/create', data);
            return response;
        } catch (e) {
            console.log(e);
            failedUpdates.push(data);
        }
        return undefined;
    };

    const editItem = async (type: string, data: any) => {
        try {
            const response = await HTTP.post('/' + type + '/edit', data);
            return response;
        } catch (e) {
            console.log(e);
        }
        return undefined;
    };

    const updateItem = async (item: SyncItem, failedUpdates: SyncItem[]) => {
        try {
            if(item.itemid){
                const response = await HTTP.post('/' + item.collection + '/update', item);
                console.log('response')
                console.log(response)
                return response;
            }
            
        } catch (e) {
            console.log(e);
            failedUpdates.push(item);
            console.log('failedUpdates')
            console.log(failedUpdates)
        }
        return undefined;
    };

    const checkForUpdates = async (lastCheck: Date) => {
        try {
            // const check = process.env.NODE_ENV === 'production' ? dayjs(lastCheck).subtract(2, 'hours').format('YYYYMMDDHHmmss') : dayjs(lastCheck).format('YYYYMMDDHHmmss');
            const response = await HTTP.post('/Sync/checkForUpdates', lastCheck);
            return response.data;
        } catch (e) {
            console.log(e);
        }
        return undefined;
    };

    const checkForSyncUpdates = async (lastSyncItem: string) => {
        try {
            const response = await HTTP.get('/Sync/checkForSyncUpdates?lastSyncItem=' + lastSyncItem);
            return response.data;
        } catch (e) {
            console.log(e);
        }
        return undefined;
    };

    const reapplySyncItems = async () => {
        console.log("reapplying updates");
        try {
            const response = await HTTP.get('/Sync/reapplySyncItems');
            console.log(response);
            return response.data;
        } catch (e) {
            console.log(e);
        }
        return undefined;
    };

    const findCurrentChecksheet = async (location: string) => {
        try {
            const response = await HTTP.get('/CheckSheet/GetCurrentChecksheetByLocation?location=' + location);
            return await response.data;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    const checkNewChecksheetEntries = async (checksheetId: string, date: Date, stableIds: string[]) => {
        try {
            const response = await HTTP.get('/CheckSheet/CheckNewChecksheetEntries?checksheetId=' + checksheetId + '&year=' + dayjs(date).year() + '&month=' + dayjs(date).month() + '&day=' + dayjs(date).date() + '&stableIds=' + stableIds.toString());
            return true;
        } catch (e) {
            return false;
        }
    }

    const getExistingChecksheetEntries = async (checksheetId: string, date: Date) => {
        try {
            const response = await HTTP.get('/CheckSheet/GetExistingChecksheetEntries?checksheetId=' + checksheetId + '&year=' + dayjs(date).year() + '&month=' + dayjs(date).month() + '&day=' + dayjs(date).date());
            return await response.data;
        } catch (e) {
            return false;
        }
    }

    const findStablesFromLocation = async (location: string) => {
        try {
            const response = await HTTP.get('/Stable/GetStablesByLocation?location=' + location);
            return await response.data;
        } catch (e) {
            console.log(e);
        }
    }

    const findCurrentRafter = async (location: string) => {
        try {
            const response = await HTTP.get('/Rafter/GetRafterByLocation?location=' + location);
            return await response.data;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }
    
    const findChecksheetTemplateFromLocation = async (location: string) => {
        try {
            const response = await HTTP.get('/ChecksheetTemplate/GetChecksheetTemplateFromLocation?location=' + location);
            return await response.data;
        } catch (e) {
            console.log(e);
        }
    }

    const getZeitaufzeichnungMonth = async (currentDate: Date, userId: string) => {
        try {
            const response = await HTTP.get('/Zeitaufzeichnung/GetZeitaufzeichnungMonth?year=' + currentDate.getFullYear() + '&month=' + currentDate.getMonth() + '&userId=' + userId);
            return await response.data;
        } catch (e) {
            console.log(e);
        }
    }

    const getVersion = async () => {
        try {
            const response = await HTTP.get('/Option/GetValue?key=version');
            return await response.data;
         } catch (e) {
             console.log(e);
         }
    }
    const getOption = async (key: string) => {
        try {
            const response = await HTTP.get('/Option/GetValue?key=' + key);
            return await response.data;
         } catch (e) {
             console.log(e);
         }
    }


    return{
        loginUser,
        loadData,
        countData,
        getItem,
        deleteItem,
        createItem,
        editItem,
        updateItem,
        checkForUpdates,
        checkForSyncUpdates,
        reapplySyncItems,
        loadChildren,
        findCurrentChecksheet,
        checkNewChecksheetEntries,
        getExistingChecksheetEntries,
        findStablesFromLocation,
        findCurrentRafter,
        findChecksheetTemplateFromLocation,
        getZeitaufzeichnungMonth,
        getVersion,
        getOption
    }
}