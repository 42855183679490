import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { useStorage } from "@/composables/useStorage";

import MainMenu from '../components/MainMenu.vue';
import dayjs from 'dayjs';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/dashboard',
        component: MainMenu,
        meta: {
            pageClass: 'dashboard',
            icon: 'i-dashboard2',
            role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7'
        },
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                meta: {
                    pageClass: 'dashboard',
                    icon: 'i-dashboard2',
                    role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7'
                },
                component: () => import ('../views/Common/Dashboard.vue')
            },
            {
                path: 'todo',
                name: 'ToDo',
                meta: {
                    pageClass: 'todo',
                    icon: 'i-todo',
                    role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7',
                    customClasses: 'todoRef',
                    disabledOnNoRafter: true
                },
                component: {},
                beforeEnter: () => {
                    return false;
                }
            },
            {
                path: 'kontrollblatt',
                name: 'Kontrollblatt',
                meta: {
                    pageClass: 'kontrollblatt',
                    icon: 'i-kontrollblatt',
                    role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7',
                    disabledOnNoRafter: true
                },
                component: () => import('../views/Lists/Checksheet.vue'),
            },
            {
                path: 'medikation',
                name: 'Medikation',
                meta: {
                    pageClass: 'medikation',
                    icon: 'i-syringe',
                    role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7',
                    disabledOnNoRafter: true
                },
                component: () => import('../views/Lists/Medication.vue'),
            },
            {
                path: 'dokumente',
                name: 'Dokumente',
                meta: {
                    pageClass: 'dokumente',
                    icon: 'i-documents',
                    role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7',
                    disabledOnNoRafter: true
                },
                component: () => import('../views/Lists/Documents.vue'),
            },
            {
                path: 'zeitaufzeichnung',
                name: 'Zeiterfassung',
                meta: {
                    pageClass: 'zeitaufzeichnung',
                    icon: 'i-timemanagement',
                    role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7',
                    customClasses: '',
                },
                component: () => import('../views/Strom/Zeitaufzeichnung.vue'),
            },
            {
                path: 'login',
                name: 'Login',
                meta: {
                    pageClass: 'login',
                    icon: 'i-log',
                    iconOnly: true,
                    customClasses: 'pullRight',
                    role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7',
                },
                component: () => import ('../views/Login.vue')
            },
            {
                path: 'switch',
                name: 'Stall ändern',
                meta: {
                    pageClass: 'changeStable',
                    icon: 'i-switch',
                    iconOnly: true,
                    customClasses: 'pullRight',
                    role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7'
                },
                component: {},
                beforeEnter: () => {
                    return false;
                }
            },
            {
                path: 'reload',
                name: 'Aktualisieren',
                meta: {
                    pageClass: 'refresh',
                    id: 'info-button',
                    icon: 'i-info',
                    iconOnly: true,
                    customClasses: 'menu-spacer pullRight',
                    role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7'
                },
                component: {},
                beforeEnter: () => {
                    return false;
                }
            },
            {
                path: 'stammdaten',
                redirect: '/stammdaten/benutzer',
                name: 'Stammdaten',
                meta: {
                    pageClass: 'stammdaten',
                    icon: 'i-settings',
                    role: '60d58e3f7975c685d0e35ca7',
                    adminMenu: true,
                    customClasses: 'clear',
                    hasSegments: true
                },
                component: () => import ('../views/Common/PassThrough.vue'),
                children: [
                    {
                        name: 'Benutzerverwaltung',
                        path: 'benutzer',
                        meta: {
                            pageClass: 'benutzer',
                            icon: 'i-users',
                            role: '60d58e3f7975c685d0e35ca7',
                            customClasses: 'hide',
                            adminMenu: true,
                            parent: 'Stammdaten',
                            prevChild: '/stammdaten/listen',
                            nextChild: '/stammdaten/logistik'
                        },
                        component: () => import ('../views/CoreData/Usermanagement.vue')
                    },
                    {
                        name: 'Abnehmer/Lieferanten',
                        path: 'logistik',
                        meta: {
                            pageClass: 'logistik',
                            icon: 'i-users',
                            role: '60d58e3f7975c685d0e35ca7',
                            customClasses: 'hide',
                            adminMenu: true,
                            parent: 'Stammdaten',
                            prevChild: '/stammdaten/benutzer',
                            nextChild: '/stammdaten/tiere'
                        },
                        component: () => import ('../views/CoreData/Logistics.vue')
                    },
                    {
                        name: 'Tiere',
                        path: 'tiere',
                        meta: {
                            pageClass: 'tiere',
                            icon: 'i-users',
                            role: '60d58e3f7975c685d0e35ca7',
                            customClasses: 'hide',
                            adminMenu: true,
                            parent: 'Stammdaten',
                            prevChild: '/stammdaten/logistik',
                            nextChild: '/stammdaten/standorte'
                        },
                        component: () => import ('../views/CoreData/Animals.vue')
                    },
                    {
                        name: 'Standorte/Ställe',
                        path: 'standorte',
                        meta: {
                            pageClass: 'standorte',
                            icon: 'i-users',
                            role: '60d58e3f7975c685d0e35ca7',
                            customClasses: 'hide',
                            adminMenu: true,
                            parent: 'Stammdaten',
                            prevChild: '/stammdaten/tiere',
                            nextChild: '/stammdaten/listen'
                        },
                        component: () => import ('../views/CoreData/Locations.vue')
                    },
                    {
                        name: 'Listen',
                        path: 'listen',
                        meta: {
                            pageClass: 'lists',
                            icon: 'i-users',
                            role: '60d58e3f7975c685d0e35ca7',
                            customClasses: 'hide',
                            adminMenu: true,
                            parent: 'Stammdaten',
                            prevChild: '/stammdaten/standorte',
                            nextChild: '/stammdaten/benutzer'
                        },
                        component: () => import ('../views/CoreData/Lists.vue')
                    },
                ],
            },
            {
                name: 'Übersicht & Planung',
                path: '/planung',
                redirect: '/planung/aktuell',
                meta: {
                    pageClass: 'planung',
                    icon: 'i-clock',
                    role: '60d58e3f7975c685d0e35ca7',
                    customClasses: '',
                },
                component: () => import ('../views/Common/PassThrough.vue'),
                children: [
                    {
                        name: 'Aktuelle Herden',
                        path: 'aktuell',
                        meta: {
                            pageClass: 'planung',
                            icon: 'i-clock',
                            role: '60d58e3f7975c685d0e35ca7',
                            customClasses: 'hide',
                            adminMenu: true,
                            parent: 'Übersicht & Planung',
                            prevChild: '/planung/archiv',
                            nextChild: '/planung/archiv'
                        },
                        component: () => import ('../views/Planning/Planning.vue')
                    },
                    {
                        name: 'Archiv',
                        path: 'archiv',
                        meta: {
                            pageClass: 'planung',
                            icon: 'i-clock',
                            role: '60d58e3f7975c685d0e35ca7',
                            customClasses: 'hide',
                            adminMenu: true,
                            parent: 'Übersicht & Planung',
                            prevChild: '/planung/aktuell',
                            nextChild: '/planung/aktuell'
                        },
                        component: () => import ('../views/Planning/PlanningArchive.vue')
                    },
                ]
            },
            {
                name: 'Auswertungen',
                path: '/auswertung',
                meta: {
                    pageClass: 'auswertung',
                    icon: 'i-analytics',
                    role: '60d58e3f7975c685d0e35ca7',
                    customClasses: 'hide',
                },
                component: () => import ('../views/CoreData/Persons.vue')
            },
        ]
    },
    
//   {
//     path: '/dashboard',
//     component: () => import ('../views/Common/Dashboard.vue')
//   },
//   {
//     path: '/medikation',
//     component: () => import ('../views/Lists/Medication.vue')
//   },
//   {
//     path: '/kontrollblatt',
//     component: () => import ('../views/Lists/Checksheet.vue'),
//   },
//   {
//     path: '/lieferschein',
//     component: () => import ('../views/Common/AddFile.vue')
//   },
//   {
//     path: '/planung/herden',
//     component: () => import ('../views/Planning/Rafters.vue')
//   },
//   {
//     path: '/stammdaten/eiererzeuger',
//     component: () => import ('../views/CoreData/EggProducers.vue')
//   },
//   {
//     path: '/stammdaten/kontrollblaetter',
//     component: () => import ('../views/CoreData/ChecksheetTemplates.vue')
//   },
//   {
//     path: '/stammdaten/standorte',
//     component: () => import ('../views/CoreData/Locations.vue')
//   },
//   {
//     path: '/stammdaten/staelle',
//     component: () => import ('../views/CoreData/Stables.vue')
//   },
//   {
//     path: '/stammdaten/medikation',
//     component: () => import ('../views/CoreData/Medication.vue')
//   },
//   {
//     path: '/stammdaten/rassen',
//     component: () => import ('../views/CoreData/Breeds.vue')
//   },
//   {
//     path: '/stammdaten/bruetereien',
//     component: () => import ('../views/CoreData/Hatcheries.vue')
//   },
//   {
//     path: '/stammdaten/rollen',
//     component: () => import ('../views/CoreData/Roles.vue')
//   },
//   {
//     path: '/stammdaten/qulaitaetsstandards',
//     component: () => import ('../views/CoreData/QualityStandards.vue')
//   },
//   {
//     path: '/stammdaten/benutzer',
//     component: () => import ('../views/CoreData/Persons.vue')
//   },
//   {
//     path: '/stammdaten/tags',
//     component: () => import ('../views/CoreData/FileTags.vue')
//   },
//   {
//     path: '/dashboard-strom',
//     component: () => import ('../views/Strom/DashboardStrom.vue')
//   },
//   {
//     path: '/zeitaufzeichnung',
//     component: () => import ('../views/Strom/Zeitaufzeichnung.vue')
//   },
//   {
//     path: '/stammdaten/zeitaufzeichnung',
//     component: () => import ('../views/Strom/ZeitaufzeichnungEntries.vue')
//   }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( async (to, from) => {

    if(to.fullPath !== '/login'){
        const { getLocalStorage } = await useStorage();
        const currentUser = await getLocalStorage('user');
        if( !currentUser ){
            router.replace('/login');
        }
    }
    else{
        const { setLocalStorage } = await useStorage();
        await setLocalStorage('user', undefined);
    }
    return true;
});

export default router
