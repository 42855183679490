import { CheckSheet, CheckSheetTemplate, Rafter, Location, Person, SyncItem, Restabling, PlannedStable, Stable } from "@/types";
import { InjectionKey, ref } from "vue";
import type { Ref } from "vue";
import { useStorage } from "./useStorage";
import { checksheetFunctions } from "./checksheetFunctions";

export const GlobalRafterKey = Symbol() as InjectionKey<{
    globalRafter: Ref<Rafter>,
    globalCustomRafter: Ref<Rafter>,
    getGlobalRafter: () => Ref<Rafter>,
    isCustomRafter: () => boolean
}>;
export const GlobalCheckSheetKey = Symbol() as InjectionKey<Ref<CheckSheet | undefined>>;
export const GlobalCheckSheetTemplateKey = Symbol() as InjectionKey<Ref<CheckSheetTemplate>>;
export const GlobalLocationKey = Symbol() as InjectionKey<Ref<Location>>;
export const GlobalUserKey = Symbol() as InjectionKey<Ref<Person>>;
export const GlobalSyncKey = Symbol() as InjectionKey<Ref<SyncItem[]>>;
export const FailedUpdatesKey = Symbol() as InjectionKey<Ref<SyncItem[]>>;

export async function loadGlobalValues(app: any){
    const { getLocalStorage } = await useStorage();

    const globalUser = ref<Person>(await getLocalStorage('user'));
    app.provide(GlobalUserKey, globalUser);

    const globalCheckSheet = ref<CheckSheet>(await getLocalStorage('currentCheckSheet'));
    app.provide(GlobalCheckSheetKey, globalCheckSheet);

    const globalCheckSheetTemplate = ref<CheckSheetTemplate>(await getLocalStorage('currentCheckSheetTemplate'));
    app.provide(GlobalCheckSheetTemplateKey, globalCheckSheetTemplate);

    const globalLocation = ref<Location>(await getLocalStorage('currentLocation'));
    app.provide(GlobalLocationKey, globalLocation);

    if( globalLocation?.value?.id ){
        const { findRafter, findExistingCheckSheet } = checksheetFunctions();
        
        const rafter = await findRafter(globalLocation.value)
        if( rafter ){
            await findExistingCheckSheet(rafter);
        }
    }

    const globalRafter = ref<Rafter>(await getLocalStorage('currentRafter'));
    const globalCustomRafter = ref<Rafter>(await getLocalStorage('currentCustomRafter'));

    const stables = await getLocalStorage('Stable');
    const locations = await getLocalStorage('Location');
    globalRafter.value?.stables?.forEach( (s: PlannedStable) => {
        s.location = stables.find( (x: Stable) => x.id == s.stable )?.location;
        s.locationObj = locations.find( (x: Location) => x.id == s.location );
    });
    globalRafter.value?.restablings?.forEach( (r: Restabling) => {
        r.newStableObj = globalRafter.value.stables?.find( (s: PlannedStable) => s.id == r.newStableId );
        r.originStableObj = globalRafter.value.stables?.find( (s: PlannedStable) => s.id == r.originStableId );
        r.newAreaObj = r.newStableObj?.areas?.top?.id == r.newAreaId ? r.newStableObj?.areas?.top : (r.newStableObj?.areas?.bottom?.id == r.newAreaId ? r.newStableObj?.areas?.bottom : undefined);
        r.originAreaObj = r.originStableObj?.areas?.top?.id == r.originAreaId ? r.originStableObj?.areas?.top : (r.originStableObj?.areas?.bottom?.id == r.originAreaId ? r.originStableObj?.areas?.bottom : undefined);
    });


    function getGlobalRafter() {
        return globalCustomRafter.value ? globalCustomRafter : globalRafter;
    }
    function isCustomRafter() {
        return globalCustomRafter.value ? true : false;
    }
    app.provide(GlobalRafterKey, { globalRafter, globalCustomRafter, getGlobalRafter, isCustomRafter });

    const globalSync = ref<SyncItem[]>(await getLocalStorage('pendingSync') ?? []);
    app.provide(GlobalSyncKey, globalSync);

    const failedUpdates = ref<SyncItem[]>(await getLocalStorage('failedUpdates') ?? []);
    app.provide(FailedUpdatesKey, failedUpdates);
}